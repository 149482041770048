<template>
  <PrimeDialog v-model:visible="visible" :header="t('freight.add-bring-service-agreement-header')" class="w-3">
    <div class="flex flex-column">
      <label for="service-agreement-name">{{ t("freight.name") }}</label>
      <InputText v-model="serviceAgreement.name" class="mb-2" data-testid="service-agreement-name" />
      <label for="service-agreement-name">{{ t("freight.customer-number") }}</label>
      <InputText
        v-model="serviceAgreement.customerNumber"
        class="mb-2"
        data-testid="service-agreement-customer-number"
      />
      <label for="service-agreement-name">{{ t("freight.client-url") }}</label>
      <InputText v-model="serviceAgreement.clientURL" class="mb-2" data-testid="service-agreement-client-url" />
      <label for="service-agreement-name">{{ t("freight.my-bring-api-uid") }}</label>
      <InputText
        v-model="serviceAgreement.myBringApiUid"
        class="mb-2"
        data-testid="service-agreement-my-bring-api-uid"
      />
      <label for="service-agreement-name">{{ t("freight.my-bring-api-key") }}</label>
      <InputText
        v-model="serviceAgreement.myBringApiKey"
        class="mb-2"
        data-testid="service-agreement-my-bring-api-key"
      />
    </div>
    <div class="flex justify-content-between">
      <PrimeButton severity="warning" label="Cancel" @click="emit('cancel')" />
      <PrimeButton
        severity="success"
        label="Save"
        @click="emit('save', serviceAgreement)"
        data-testid="save-agreement-btn"
      />
    </div>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { NewBringServiceAgreement } from "../model/NewBringServiceAgreement";

const { t } = useI18n();

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "save", value: NewBringServiceAgreement): void;
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed({
  get: () => props.showDialog,
  set: (value) => {
    emit("update:showDialog", value);
  },
});

const props = defineProps<{
  showDialog: boolean;
}>();
const serviceAgreement = ref<NewBringServiceAgreement>(new NewBringServiceAgreement());
</script>

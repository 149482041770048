export class BringServiceAgreement {
  id = "";
  customerNumber = "";
  clientURL = "";
  name = "";
  companyId = "";
  clientId = "";
  myBringApiKey = "";
  myBringApiUid = "";
}

import { defineStore } from "pinia";
import { freightMethodApi } from "./FreightMethodApi";
import { useErrorHandler } from "../ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { FreightMethod } from "./model/FreightMethod";
import { ref } from "vue";
import { v4 as uuid } from "uuid";

export const useFreightMethodStore = defineStore("freightMethods", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const freightMethods = ref<FreightMethod[]>([]);
  const loading = ref(false);
  const loadFailed = ref(false);
  const saving = ref(false);

  const getAllFreightMethods = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      freightMethods.value = await freightMethodApi.getAll(authHeaders);
    } catch (error) {
      loadFailed.value = true;
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const addFreightMethod = async (freight: FreightMethod): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      freight.id = uuid();
      await freightMethodApi.add(authHeaders, freight);
      freightMethods.value.push(freight);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };

  const updateFreightMethod = async (freight: FreightMethod): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await freightMethodApi.update(authHeaders, freight);
      const index = freightMethods.value.findIndex((x: FreightMethod) => x.id === freight.id);
      freightMethods.value[index] = freight;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };

  const deleteFreightMethod = async (id: string): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await freightMethodApi.delete(authHeaders, id);
      freightMethods.value = freightMethods.value.filter((fm: FreightMethod) => fm.id !== id);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      saving.value = false;
    }
  };
  return {
    freightMethods,
    loading,
    loadFailed,
    saving,
    getAllFreightMethods,
    addFreightMethod,
    updateFreightMethod,
    deleteFreightMethod,
  };
});

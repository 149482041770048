import { createApp } from "vue";
import { i18n } from "./locales/i18n";
import { createPinia } from "pinia";

import PrimeVue from "primevue/config";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import FileUpload from "primevue/fileupload";

import { vue3Debounce } from "vue-debounce";

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();

  app.use(PrimeVue);
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("Toast", Toast);
  app.component("Checkbox", Checkbox);
  app.component("PrimeButton", Button);
  app.component("MultiSelect", MultiSelect);
  app.component("InputText", InputText);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("PrimeDialog", Dialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("FileUpload", FileUpload);
  app.component("Dropdown", Dropdown);

  app.directive(
    "debounce",
    vue3Debounce({
      listenTo: "input",
    }),
  );
}

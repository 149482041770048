import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { bringIntegrationApi } from "./BringIntegrationApi";

export function useBringIntegration() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getShippingServicesByCustomerNumber = async (customerNumber: string) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringIntegrationApi.getShippingServicesByCustomerNumber(authHeaders, customerNumber);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getShippingServicesByCustomerNumber,
  };
}

import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { BadRequestError, InternalServerError, NotFoundError } from "@cumulus/http";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useI18n } from "vue-i18n";

export function useErrorHandler() {
  const { instance } = useAuth();
  const toast = useCumulusToast(useToast());
  const { t } = useI18n();

  const handleError = async (error: unknown) => {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
      return;
    }

    let errorMessage = t("common.error-detail.unknown");
    if (error instanceof InternalServerError) {
      errorMessage = t("common.error-detail.server-error");
    }

    if (error instanceof NotFoundError) {
      errorMessage = t("common.error-detail.not-found");
    }

    if (error instanceof BadRequestError) {
      errorMessage = t("common.error-detail.bad-request");
    }

    toast.add({
      severity: "error",
      summary: t("common.error"),
      detail: errorMessage,
    });
  };

  return { handleError };
}

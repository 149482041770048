import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { NewBringServiceAgreement } from "./model/NewBringServiceAgreement";
import { bringServiceAgreementApi } from "./BringServiceAgreementsApi";
import { BringServiceAgreement } from "./model/BringServiceAgreement";

export function useBringServiceAgreements() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllServiceAgreements = async (): Promise<BringServiceAgreement[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringServiceAgreementApi.getAllBringServiceAgreements(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const addBringServiceAgreement = async (newServiceAgreement: NewBringServiceAgreement) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringServiceAgreementApi.addBringServiceAgreement(authHeaders, newServiceAgreement);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateBringServiceAgreement = async (serviceAgreement: BringServiceAgreement) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringServiceAgreementApi.updateBringServiceAgreement(authHeaders, serviceAgreement);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };
  const deleteBringServiceAgreement = async (id: string) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringServiceAgreementApi.deleteBringServiceAgreement(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getAllServiceAgreements,
    addBringServiceAgreement,
    updateBringServiceAgreement,
    deleteBringServiceAgreement,
  };
}

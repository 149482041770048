export class Bring {
  bringServiceAgreement = new BringServiceAgreement();
  bringShippingService = new BringShippingService();
}
export class BringShippingService {
  serviceFamily = "";
  serviceName = "";
  serviceCode = "";
  requestCode = "";
  type = "";
}
export class BringServiceAgreement {
  id = "";
  customerNumber = "";
  clientURL = "";
  name = "";
  companyId = "";
}

import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Routes",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-singout",
          path: "/oauth2-singout",
          component: (): Component => import("../components/OAuth2Signout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../components/Failed.vue"),
        },
      ]
    : [];

export const freightRoutes: RouteRecordRaw[] = [
  {
    name: "methods",
    path: "/freight/methods",
    component: (): Component => import("@/freight-method/list/FreightMethods.vue"),
    meta: {
      authRequired: true,
    },
  },
];

import { createRouter, createWebHistory } from "vue-router";
import { mainRoutes, freightRoutes } from "./routes";
import { registerGuard } from "@cumulus/event-bus";

const routes = [...mainRoutes, ...freightRoutes];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  linkActiveClass: "active",
});

registerGuard(router);

export default router;

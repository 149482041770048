import { Bring } from "@/bring-integration/Bring";
import { Available } from "./Available";
import { FreightMethodTax } from "./FreightMethodTax";

export class FreightMethod {
  id = "";
  companyId = "";
  clientId = "";
  name = "";
  description = "";
  shortName = "";
  available = new Available();
  tax: FreightMethodTax = new FreightMethodTax();
  bring: Bring | null = null;
}
